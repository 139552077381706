<style scoped lang="less">
  .title {
    font-weight: 800;
    font-size: 15px;
  }
</style>

<template>
  <div>
    <div class="title">
      <span>{{data.type}}</span>
    </div>
    <div v-for="(prescription, i) in data.prescriptionData" :key="prescription.id + '-' + i">
      <div>
        总剂数: <span>{{prescription.timesNum}}</span>
        用药频率: <span>{{prescription.usageFrequency}}</span>
      </div>
      <div v-for="(medicine, i) in prescription.medicineData" :key="medicine.id + '-' + i">
        {{medicine.medicineName}}({{medicine.volume}}袋)
      </div>
    </div>
  </div>
</template>

<script>
import mixins from './mixins'
export default {
  mixins: [mixins]
}
</script>
